import { Dish, DishService } from 'gc-common';
import React, { useState } from 'react';
import { DeviceEventEmitter, KeyboardAvoidingView, ScrollView, Text, View } from 'react-native';
import { Colors } from '../../constants/Colors';
import { _useModalHook } from '../../context/modalContext';
import { ImageService } from '../../services/image/imageService';
import { RootStackScreenProps } from '../../types';
import { GCButton } from '../Button/GCButton';
import { InputRow } from '../CustomInputText/InputRow';
import { SelectCard } from '../SelectCard/SelectCard';

export const DishCreationPage = ({ route, navigation }: RootStackScreenProps<'DishCreation'>) => {
    const { restaurantId, categories } = route.params;
    const noUpload = route.params.noUpload;
    const modal = _useModalHook();

    const [newDish, setNewDish] = useState({
        image: undefined,
        name: undefined as string | undefined,
        price: undefined as string | undefined,
        description: undefined,
        categories: '',
        selectedCategories: {} as Record<string, boolean>,
    });
    const [nameSuggestions, setNameSuggestions] = useState<string[]>();
    const onNameTextChanged = async (text: string) => {
        const token = '';
        // newDish.name = text;
        setNewDish({
            ...newDish,
            name: text,
        });
        // const rsp = await axios.get(
        //     `https://api.spoonacular.com/food/menuItems/suggest?query=${text}&number=10&apiKey=291c7e6655394c6483613e963292d919`
        // );
        //
        // setNameSuggestions(rsp.data.results.map((i: any) => i.title));
    };
    const onNameSelected = async (item: string) => {
        setNameSuggestions(undefined);
        newDish.name = item;
    };

    const updateField = (field: string, value: string) => {
        setNewDish({
            ...newDish,
            [field]: value.trim(),
        } as any);
    };
    const updateSelectedCategories = (cat: string, value: any) => {
        newDish.selectedCategories[cat] = value;
    };

    const onSelection = (dish: Dish) => {
        DeviceEventEmitter.emit('dish-created', dish);
    };

    const validatePrice = () => {
        const priceText = newDish.price;
        if (!priceText) {
            modal.open('warning', 'Price is required');
            return false;
        }
        let price = priceText?.toString().trim();
        let priceRegex = /^\d+(\.\d{1,2})?$/;
        if (price.length === 0) {
            modal.open('warning', 'Price is required');
            return false;
        } else if (!priceRegex.test(price)) {
            modal.open('warning', 'Please enter a valid price');
            return false;
        }
        return true;
    };

    const onCreateDish = async () => {
        if (!newDish.name) {
            modal.open('warning', 'Dish name is required.');
            return;
        }

        if (!validatePrice()) {
            return;
        }

        if (newDish.categories) {
            newDish.categories
                .split(',')
                .map((i) => i.trim())
                .map((i) => (newDish.selectedCategories[i] = true));
        }

        modal.open('loader');
        let image_ids: string[] = [];
        if (newDish.image) {
            const { image_id } = (await ImageService.uploadImages([newDish.image]))[0];
            image_ids = [image_id];
        }
        const newObj: Partial<Dish> = {
            name: newDish.name,
            restaurant_id: restaurantId,
            description: newDish.description || '',
            // price: newDish.price ? parseFloat(newDish.price) : undefined,
            categories: Object.keys(newDish.selectedCategories).filter(
                (v) => newDish.selectedCategories[v]
            ),
            rating: 0,
            image_ids: image_ids,
        };
        if (newDish.price) newObj.price = parseFloat(newDish.price);
        const rsp = await DishService.create(newObj);
        // modal.close();
        if (rsp.data) {
            modal.open('success', undefined, () => {
                navigation.pop();
                onSelection(rsp.data);
            });
        } else {
            modal.open('fail', 'Sorry, something went wrong.');
        }
    };

    return (
        <KeyboardAvoidingView behavior={'height'} keyboardVerticalOffset={130} enabled>
            {modal.render()}
            <ScrollView style={{ margin: 10 }}>
                {!noUpload && (
                    <InputRow
                        type={'image'}
                        title={'Upload a pic (Optional)'}
                        initValue={newDish.image}
                        onChangeText={(t) => updateField('image', t)}
                    />
                )}
                <View style={{ zIndex: 15 }}>
                    <InputRow
                        initValue={newDish.name}
                        title={'Name'}
                        onChangeText={onNameTextChanged}
                    />
                </View>
                <InputRow
                    title={'Price'}
                    keyboardType={'numeric'}
                    onChangeText={(t) => updateField('price', t)}
                />
                <InputRow
                    title={'Description (Optional)'}
                    multiline={true}
                    onChangeText={(t) => updateField('description', t)}
                />

                <InputRow
                    title={'Categories (Optional)'}
                    placeholder={'Separated by "," (comma) if multiple.'}
                    onChangeText={(t) => updateField('categories', t)}
                />
                {categories.length > 0 && (
                    <>
                        <Text style={{ fontSize: 12, color: Colors.dark_gray }}>
                            Or selected from below:
                        </Text>
                        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                            {categories.map((i, index) => (
                                <SelectCard
                                    text={i}
                                    key={index}
                                    onChange={(v) => updateSelectedCategories(i, v)}
                                    initSelected={newDish.selectedCategories[i]}
                                />
                            ))}
                        </View>
                    </>
                )}

                <View style={{ height: 20 }} />

                <GCButton text={'Create'} onClick={onCreateDish} />
                <View style={{ height: 100 }} />
            </ScrollView>
        </KeyboardAvoidingView>
    );
};
