import { AntDesign, Entypo, Feather } from '@expo/vector-icons';
import { Comment, Post, PostService, UserService } from 'gc-common';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
    Alert,
    Image,
    KeyboardAvoidingView,
    Platform,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { ActionSheet } from '../../components/ActionSheet/ActionSheet';
import { styles as commentStyles } from '../../components/CommentCard/styles';
import CustomInputText from '../../components/CustomInputText/CustomInputText';
import GCImageView from '../../components/GCImageView';
import { GotoHomeWidget } from '../../components/GotoHomeWidget';
import { Ratings } from '../../components/Ratings/Ratings';
import Spinner from '../../components/Spinner/Spinner';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { Colors } from '../../constants/Colors';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { Env } from '../../Env';
import { LikeEnum, useLike } from '../../hooks/useLike';
import { RootStackScreenProps } from '../../types';
import { CommonUtils } from '../../utils/commonUtils';
import { Logger } from '../../utils/logger';
import { styles } from './styles';

const regexFiveOrMoreConsecutiveSpaces = /\s{5,}/g;

export const PostPage = ({ route, navigation }: RootStackScreenProps<'PostDetail'>) => {
    const { id: postId } = route.params;

    const [post, setPost] = useState<Post>();
    const [comments, setComments] = useState<Comment[]>([]);
    const [comment, setComment] = useState('');

    const modal = useModal();
    const auth = useAuth();
    const like = useLike(LikeEnum.post, postId, false);

    useEffect(() => {
        console.log('PostDetail', postId);
        fetchData();
    }, []);

    useEffect(() => {
        if (post?.dish_id && auth.isLoggedIn) {
            UserService.incrementUserView(post.dish_id, postId);
        }

        navigation.setOptions({
            headerRight: () => (
                <>
                    <TouchableOpacity
                        onPress={() => {
                            CommonUtils.share(`${Env.WEB_SHARE_ROOT}/post?id=${postId}`);
                        }}
                    >
                        <Feather name={'share-2'} size={20} />
                    </TouchableOpacity>
                </>
            ),
            headerLeft: () => (
                <TouchableOpacity
                    onPress={() => {
                        navigation.goBack();
                    }}
                >
                    <Feather name={'arrow-left'} size={20} />
                </TouchableOpacity>
            ),
        });
    }, [post?.dish_id]);

    const fetchData = async () => {
        const data = await PostService.get(postId);
        setPost(data.data);

        const commentsData = await PostService.getCommentsForPost(postId);
        setComments(commentsData.data);
    };

    const sendComment = async () => {
        if (!auth.isLoggedIn) {
            navigation.navigate('SignIn' as any);
            return;
        }
        if (comment.trim().length == 0) {
            modal.open('fail', 'Your comment is empty');
            return;
        }
        if (regexFiveOrMoreConsecutiveSpaces.test(comment)) {
            modal.open('fail', 'Your comment contains too many consecutive spaces');
            return;
        }
        modal.open('loader');
        const data = await PostService.createComment(postId, comment);
        // console.log(data.data);

        if (data.errorMessage) {
            modal.open('fail', data.errorMessage);
            Logger.error('[Comment] Post comment failed', { context: data.errorMessage });
            return;
        }

        modal.open('success');
        setComment('');
        fetchData();
    };

    const onLike = async () => {
        like.setIsLiking(true);
        await like.onLike();
        await fetchData();
        like.setIsLiking(false);
    };

    const isWeb = Platform.OS === 'web';

    const onReport = async () => {
        if (isWeb) {
            const userConfirmed = window.confirm('Are you sure you want to report this post?');
            if (userConfirmed) {
                modal.open(
                    'success',
                    'Thank you for your report! \n We will review this within 24h.'
                );
                return;
            }
        } else {
            Alert.alert(
                'Report Post',
                'Are you sure you want to report this post?',
                [
                    {
                        text: 'Cancel',
                        style: 'cancel',
                        onPress: () => console.log('Cancel Pressed'),
                    },
                    {
                        text: 'Report',
                        onPress: () => {
                            modal.open(
                                'success',
                                'Thank you for your report! \n We will review this within 24h.'
                            );
                        },
                    },
                ],
                { cancelable: true }
            );
        }
    };

    const handleActionSheetSelection = (index: number | undefined) => {
        const selectedOption = ActionSheetProps.options[index as number];

        if (selectedOption === 'Edit') {
            handleEditPost();
        } else if (selectedOption === 'Delete') {
            handleDeletePost(post?.dish_post_id as string);
        } else if (selectedOption === 'Report') {
            onReport();
        }
    };

    const ActionSheetProps = {
        options:
            auth.user?.user_id === post?.user_id
                ? ['Edit', 'Delete', 'Cancel']
                : ['Report', 'Cancel'],
        destructiveButtonIndex: auth.user?.user_id === post?.user_id ? 1 : -1,
        cancelButtonIndex: auth.user?.user_id === post?.user_id ? 2 : 1,
        onSelection: handleActionSheetSelection,
        title: auth.user?.user_id === post?.user_id ? 'Your Review:' : 'Review:',
        message:
            post && post?.content.length > 50
                ? post?.content.slice(0, 50) + ' ... '
                : post?.content,
    };

    const onDeleteComment = async (commentId: string) => {
        const res = await PostService.deleteComment(commentId);
        if (res?.errorMessage) {
            modal.open('fail', res?.errorMessage);
        }
        fetchData();
    };

    const handleEditPost = async () => {
        navigation.navigate('PostCreation', { post: post });
    };

    const handleDeletePost = async (dishPostId: string) => {
        // make sure user can only delete their own post
        if (auth.user?.user_id !== post?.user_id) {
            modal.open('fail', 'You can only delete your own post');
            return;
        }

        if (isWeb) {
            const userConfirmed = window.confirm('Are you sure you want to delete this post?');
            if (userConfirmed) {
                onDeletePost(dishPostId);
                return;
            }
        } else {
            Alert.alert(
                'Delete Post',
                'Are you sure you want to delete this post?',
                [
                    {
                        text: 'Cancel',
                        style: 'cancel',
                        onPress: () => console.log('Cancel Pressed'),
                    },
                    {
                        text: 'Delete',
                        onPress: () => onDeletePost(dishPostId),
                    },
                ],
                { cancelable: true }
            );
        }
    };

    const onDeletePost = async (dishPostId: string) => {
        const res = await PostService.deleteDishPost(dishPostId);
        if (res?.errorMessage) {
            modal.open('fail', res?.errorMessage);
        }
        fetchData();
        navigation.navigate('User', { id: auth.user?.user_id });
    };

    const [viewImg, setViewImg] = useState({
        visible: false,
        idx: 1,
    });

    return (
        <>
            <View style={styles.wrapper}>
                <KeyboardAwareScrollView>
                    {post && (
                        <View>
                            <View style={[commentStyles.cardWrapper]}>
                                <View style={commentStyles.avatarWrapper}>
                                    <TouchableOpacity
                                        onPress={() =>
                                            navigation.push('User', { id: post.user_id })
                                        }
                                    >
                                        <Image
                                            style={commentStyles.avatarImage}
                                            source={{
                                                uri: post.user_profile_photo_url,
                                            }}
                                        />
                                    </TouchableOpacity>
                                </View>
                                <View style={commentStyles.contentWrapper}>
                                    <View style={commentStyles.nameWrapper}>
                                        <Text style={commentStyles.title}>
                                            {post.user_display_name}
                                        </Text>
                                        <ActionSheet
                                            title={ActionSheetProps.title}
                                            message={ActionSheetProps.message}
                                            options={ActionSheetProps.options}
                                            destructiveButtonIndex={
                                                ActionSheetProps.destructiveButtonIndex
                                            }
                                            cancelButtonIndex={ActionSheetProps.cancelButtonIndex}
                                            onSelection={(index) =>
                                                handleActionSheetSelection(index)
                                            }
                                        />
                                    </View>
                                    <View style={commentStyles.infoWrapper}>
                                        <Text style={[commentStyles.infoText, { flex: 1 }]}>
                                            {post.address.city}
                                        </Text>
                                        <Text style={commentStyles.infoText}>
                                            {moment(post.last_updated * 1000).fromNow()}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <Ratings rating={post.rating} />
                            <Text style={commentStyles.content}>{post.content}</Text>
                            <View style={commentStyles.imagesWrapper}>
                                {post.image_urls.map((image, index) => (
                                    <View key={image} style={{ width: '33%', padding: 2 }}>
                                        <TouchableOpacity
                                            onPress={() =>
                                                setViewImg({
                                                    visible: true,
                                                    idx: index,
                                                })
                                            }
                                        >
                                            <Image
                                                style={commentStyles.contentImage}
                                                source={{
                                                    uri: image,
                                                }}
                                            />
                                        </TouchableOpacity>
                                    </View>
                                ))}
                            </View>
                            <View
                                style={[styles.restaurantInfoWrapper, CommonStyles.flexRowCenter]}
                            >
                                {like.isLiking ? (
                                    <Spinner style={styles.userLikeButton} />
                                ) : (
                                    <TouchableOpacity onPress={onLike}>
                                        <Text style={styles.restaurantInfoText}>
                                            <AntDesign
                                                name={like.isLike ? 'heart' : 'hearto'}
                                                style={[
                                                    styles.userLikeButtonIcon,
                                                    like.isLike &&
                                                        styles.userActivationLikeButtonIcon,
                                                ]}
                                            />{' '}
                                            {post?.num_of_likes > 0 ? post.num_of_likes : ''}
                                        </Text>
                                    </TouchableOpacity>
                                )}
                                <View style={{ flex: 1 }}></View>
                            </View>
                            <View style={styles.restaurantInfoWrapper}>
                                <TouchableOpacity
                                    style={{ marginVertical: 5 }}
                                    onPress={() => {
                                        navigation.push('RestaurantDetail', {
                                            id: post.restaurant_id,
                                        });
                                    }}
                                >
                                    <Text style={styles.restaurantInfoText}>
                                        <Feather name={'home'} size={15} /> {post.restaurant_name}{' '}
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {
                                        navigation.push('DishDetail', { id: post.dish_id });
                                    }}
                                >
                                    <Text style={styles.restaurantInfoText}>
                                        <Entypo name={'bowl'} size={15} /> {post.dish_name}
                                    </Text>
                                </TouchableOpacity>
                            </View>
                            {/*<View style={commentStyles.infoWrapper}>*/}
                            {/*    <Text style={[commentStyles.extraInfoText, styles.likesText]}>*/}
                            {/*        <FontAwesome name={'heart'} size={15} /> {post.num_of_likes}*/}
                            {/*    </Text>*/}
                            {/*</View>*/}
                        </View>
                    )}
                    <GotoHomeWidget />
                    <View style={styles.commentsContainer}>
                        {comments.map((item) => {
                            return (
                                <View style={styles.commentItem} key={item.dish_post_comment_id}>
                                    <Text style={styles.content}>
                                        <Text style={styles.author}>
                                            {item.user_display_name}:{' '}
                                        </Text>
                                        {item.content}
                                        &nbsp;&nbsp;
                                        {auth.user?.user_id === item.user_id && (
                                            <TouchableOpacity
                                                onPress={() =>
                                                    onDeleteComment(item.dish_post_comment_id)
                                                }
                                            >
                                                <AntDesign
                                                    name="delete"
                                                    size={14}
                                                    color={Colors.primary}
                                                />
                                            </TouchableOpacity>
                                        )}
                                    </Text>
                                </View>
                            );
                        })}
                    </View>
                </KeyboardAwareScrollView>
                {/*<BottomButton type={'primary'} text={'Post a comment'}/>*/}
            </View>
            <KeyboardAvoidingView
                behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                style={styles.bottomWrapper}
                keyboardVerticalOffset={100}
            >
                <View style={styles.inner}>
                    <View style={styles.sendInputWrapper}>
                        <CustomInputText
                            initValue={comment}
                            multiline={true}
                            placeholder={'Post your comment...'}
                            onChangeText={(t) => setComment(t)}
                        />
                    </View>
                    <TouchableOpacity style={styles.sendIcon} onPress={sendComment}>
                        <Feather name={'send'} size={25} />
                    </TouchableOpacity>
                </View>
            </KeyboardAvoidingView>
            {post && (
                <GCImageView
                    images={post?.image_urls.map((item) => {
                        return { uri: item };
                    })}
                    imageIndex={viewImg.idx}
                    visible={viewImg.visible}
                    onRequestClose={() =>
                        setViewImg({
                            visible: false,
                            idx: 1,
                        })
                    }
                />
            )}
        </>
    );
};
