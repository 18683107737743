import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useAtom } from 'jotai/index';
import React, { useState } from 'react';
import { Keyboard, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { GCButton } from '../../components/Button/GCButton';
import CustomInputText from '../../components/CustomInputText/CustomInputText';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { Colors } from '../../constants/Colors';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { authService } from '../../services/auth/authService';
import { AuthStateAtom } from '../../states/authState';
import { RootStackParamList, RootStackScreenProps } from '../../types';
import { Logger } from '../../utils/logger';
import { useAppleLogin } from '../SignIn/useAppleLogin';
import { useGoogleLogin } from '../SignIn/useGoogleLogin';
import { styles } from './styles';
//todo: add apis

export default function SignUpScreen({ route }: RootStackScreenProps<'SignUp'>) {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const modal = useModal();
    const auth = useAuth();
    const [authState, setAuthState] = useAtom(AuthStateAtom);
    const [signUpValues, setSignUpValues] = useState({
        email: authState.userEmail || '',
        password: '',
        confirmPassword: '',
        code: '',
    });
    const [message, setMessage] = useState('');
    const [alert, setAlert] = useState(false);
    const [codeSent, setCodeSent] = useState(false);

    const googleLogin = useGoogleLogin((data) => {
        if (!data.success) {
            modal.open('fail', 'Sorry, we cannot sign you in with Google. Please try again later.');
            return;
        }

        navigation.pop();
    });

    const appleLogin = useAppleLogin((data) => {
        if (!data.success) {
            modal.open('fail', 'Sorry, we cannot sign you in with Apple. Please try again later.');
            return;
        }
        navigation.pop();
    });

    const updateWarnMessage = (text: string) => {
        setMessage(text);
        modal.open('warning', text);
    };

    const validateEmail = () => {
        let emailRegex = /^[a-zA-Z0-9\.\+_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        let email = authState.userEmail.toString();
        if (email.length === 0) {
            updateWarnMessage('Email is required');
            setAlert(true);
            return false;
        } else if (!emailRegex.test(email)) {
            updateWarnMessage('Invalid email');
            setAlert(true);
            return false;
        } else {
            updateWarnMessage('');
            setAlert(false);
            return true;
        }
    };

    const validatePassword = () => {
        const passwordText = signUpValues.password;
        let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
        let password = passwordText.toString();

        if (password.length < 6) {
            updateWarnMessage('Password must be at least 6 characters');
            setAlert(true);
            return false;
        } else if (password !== signUpValues.confirmPassword) {
            updateWarnMessage('Password does not match');
            setAlert(true);
            return false;
        } else if (!passwordRegex.test(password)) {
            updateWarnMessage(
                'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character'
            );
            setAlert(true);
            return false;
        } else {
            updateWarnMessage('');
            setAlert(false);
            return true;
        }
    };

    const handleChange = (props: any, value: any) => {
        setSignUpValues({ ...signUpValues, [props]: value.trim() });

        if (props === 'email') {
            setAuthState({ ...authState, userEmail: value.trim() });
        }
    };
    const handleBack = () => {
        navigation.goBack();
    };
    const handleSignIn = () => {
        navigation.goBack();
    };

    const handleCreateAnAccount = async () => {
        console.log('click Create an Account');
        console.log('authState.userEmail: ' + authState.userEmail);
        console.log('signUpValues.password: ' + signUpValues.password);
        console.log('signUpValues.confirmPassword: ' + signUpValues.confirmPassword);
        Keyboard.dismiss();

        if (validateEmail() && validatePassword()) {
            modal.open('loader');
            const result = await authService.signUp(
                authState.userEmail || '',
                signUpValues.password
            );
            modal.close();

            if (!result.errorMessage) {
                Logger.log('[Signup] Signup successful');
                navigation.pop();
                auth.checkIsLoggedIn();
                navigation.navigate('EditProfile', {});
            } else {
                Logger.error(result.errorMessage);
                modal.open('fail', result.errorMessage);
            }
        } else {
            // modal.open('warning', message);
        }
    };
    const verifyCode = async () => {
        modal.open('loader');
        const result = await authService.codeVerify(
            authState.userEmail,
            signUpValues.password,
            signUpValues.code
        );
        if (!result.errorMessage) {
            modal.close();
            navigation.pop();
            auth.checkIsLoggedIn();
            navigation.navigate('EditProfile', {});
        } else {
            Logger.error(result.errorMessage);
            modal.open('fail', result.errorMessage);
        }
    };
    const handleFacebook = () => {
        console.log('click facebook');
    };
    const handleGoogle = async () => {
        googleLogin.promptAsync();
        //navigation.goBack();
    };
    const handleApple = () => {
        appleLogin.signIn();
    };
    const handleWechat = () => {
        console.log('click wechat');
    };

    return (
        <ScrollView style={styles.pageWrapper}>
            <View style={styles.screenContainer}>
                <View style={styles.topBarContainer}>
                    <TouchableOpacity onPress={handleBack}>
                        <AntDesign name="arrowleft" size={24} />
                    </TouchableOpacity>
                </View>

                <View>
                    <Text style={styles.title}>Sign Up</Text>
                    <Text style={styles.subTitle}>Explore the best dish for you</Text>
                </View>
                {!codeSent && (
                    <>
                        <View style={styles.inputContainer}>
                            <Text style={styles.inputTitle}>Email</Text>
                            <View style={styles.inputView}>
                                <CustomInputText
                                    initValue={authState.userEmail}
                                    isPassword={false}
                                    leftIcon={'user'}
                                    placeholder={'example@email.com'}
                                    onChangeText={(text) => handleChange('email', text)}
                                />
                            </View>
                            <Text style={styles.inputTitle}>Password</Text>
                            <View style={[styles.inputView, { marginBottom: 10 }]}>
                                <CustomInputText
                                    isPassword={true}
                                    leftIcon={'lock'}
                                    placeholder={'Enter your password'}
                                    onChangeText={(text) => handleChange('password', text)}
                                />
                            </View>
                            <Text
                                style={{
                                    fontFamily: 'eina01',
                                    fontSize: 12,
                                    marginBottom: 20,
                                    lineHeight: 15,
                                }}
                            >
                                Your password must at least be 6 characters.
                            </Text>
                            <Text style={styles.inputTitle}>Confirm Password</Text>
                            <View style={styles.inputView}>
                                <CustomInputText
                                    isPassword={true}
                                    leftIcon={'lock'}
                                    placeholder={'Enter your password again'}
                                    onChangeText={(text) => handleChange('confirmPassword', text)}
                                />
                            </View>
                        </View>
                        <View
                            style={[
                                CommonStyles.flexRowCenter,
                                { marginTop: 10, flexWrap: 'wrap' },
                            ]}
                        >
                            <Text style={{ fontSize: 12, lineHeight: 18, fontFamily: 'eina01' }}>
                                By processing you also agree to the&nbsp;
                            </Text>
                            <TouchableOpacity onPress={() => navigation.navigate('EULA')}>
                                <Text
                                    style={{
                                        fontSize: 12,
                                        lineHeight: 18,
                                        fontFamily: 'eina01',
                                        color: Colors.primary,
                                    }}
                                >
                                    End User License Agreement,&nbsp;
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => navigation.navigate('TermsOfService')}>
                                <Text
                                    style={{
                                        fontSize: 12,
                                        lineHeight: 18,
                                        fontFamily: 'eina01',
                                        color: Colors.primary,
                                    }}
                                >
                                    Term of Service&nbsp;
                                </Text>
                            </TouchableOpacity>
                            <Text style={{ fontSize: 12, lineHeight: 18, fontFamily: 'eina01' }}>
                                and&nbsp;
                            </Text>
                            <TouchableOpacity onPress={() => navigation.navigate('PrivacyPolicy')}>
                                <Text
                                    style={{
                                        fontSize: 12,
                                        lineHeight: 18,
                                        fontFamily: 'eina01',
                                        color: Colors.primary,
                                    }}
                                >
                                    Privacy Policy.
                                </Text>
                            </TouchableOpacity>
                        </View>

                        <Text>{message}</Text>
                        <TouchableOpacity
                            style={styles.buttonContainer}
                            onPress={handleCreateAnAccount}
                        >
                            <Text
                                style={{
                                    textAlign: 'center',
                                    fontSize: 15,
                                    color: Colors.white,
                                    fontFamily: 'eina01-semibold',
                                }}
                            >
                                {' '}
                                Create an account{' '}
                            </Text>
                        </TouchableOpacity>

                        <View style={styles.text}>
                            <Text style={{ fontSize: 12, fontFamily: 'eina01' }}>
                                Already have an account?&nbsp;
                            </Text>
                            <TouchableOpacity onPress={handleSignIn}>
                                <Text
                                    style={{
                                        fontSize: 12,
                                        fontFamily: 'eina01',
                                        color: Colors.primary,
                                    }}
                                >
                                    Sign In
                                </Text>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.divider}>
                            <View style={{ flex: 1, height: 0.4, backgroundColor: Colors.gray }} />
                            <Text
                                style={{
                                    flex: 0.7,
                                    textAlign: 'center',
                                    color: Colors.dark_gray,
                                    fontFamily: 'eina01',
                                }}
                            >
                                sign in with
                            </Text>
                            <View style={{ flex: 1, height: 0.4, backgroundColor: Colors.gray }} />
                        </View>

                        <View style={styles.socialButtonContainer}>
                            <TouchableOpacity onPress={handleGoogle}>
                                <FontAwesome name="google" size={42} color="black" />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={handleApple}>
                                <FontAwesome name="apple" size={42} />
                            </TouchableOpacity>
                        </View>
                    </>
                )}

                {codeSent && (
                    <View style={{ marginTop: 20 }}>
                        <Text style={styles.inputTitle}>Verification Code</Text>
                        <View style={styles.inputView}>
                            <CustomInputText
                                isPassword={true}
                                leftIcon={'lock'}
                                placeholder={'Enter your verification code'}
                                onChangeText={(text) => handleChange('code', text)}
                            />
                        </View>
                        <Text
                            style={{
                                fontFamily: 'eina01',
                                fontSize: 12,
                                marginBottom: 20,
                                lineHeight: 15,
                            }}
                        >
                            We have sent a verification code to your email, please enter the code
                            here
                        </Text>
                        <GCButton text={'Submit'} onClick={verifyCode} />
                    </View>
                )}
            </View>
            {modal.render()}
        </ScrollView>
    );
}
