import { AddressModel, formatAddress } from 'gc-common';
import { Linking, Platform, Share } from 'react-native';

export { formatAddress, formatPriceRange, makeid, meterToMiles, parseAddress } from 'gc-common';

export const openMapLink = (addr?: AddressModel, forceGoogle = false) => {
    if (addr) {
        const fullAddress = formatAddress(addr);
        let url = Platform.select({
            ios: `http://maps.apple.com/?q=${formatAddress(addr)}`,
            android: `geo:0,0?q=${fullAddress}`,
        });
        if (forceGoogle) {
            url = `https://maps.google.com/?q=${formatAddress(addr)}`;
        }
        url && Linking.openURL(url);
    }
};

export const share = (url: string, message?: string) => {
    if (Platform.OS === 'ios') {
        return Share.share({
            message,
            url,
        });
    }
    if (Platform.OS === 'android') {
        return Share.share(
            {
                message: url,
            },
            { dialogTitle: message }
        );
    }
    if (Platform.OS === 'web') {
        return Share.share({
            message,
            url,
        });
    }
};

/**
 * Shuffle an array
 * @param array
 */
export const shuffle = (array: any[]) => {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

export const urlQueryToObj = () => {
    var search = location.search.substring(1);
    if (!search) return '{}';
    return JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
            return key === '' ? value : decodeURIComponent(value);
        }
    );
};

export const CommonUtils = {
    share,
    openMapLink,
    shuffle,
    urlQueryToObj,
};
