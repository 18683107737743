import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { Keyboard, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import CustomInputText from '../../components/CustomInputText/CustomInputText';
import { PolicyWidget } from '../../components/PolicyWidget';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { Colors } from '../../constants/Colors';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { authService } from '../../services/auth/authService';
import { AuthStateAtom } from '../../states/authState';
import { RootStackParamList, RootStackScreenProps, RootTabScreenProps } from '../../types';
import { styles } from './styles';
import { useAppleLogin } from './useAppleLogin';
import { useGoogleLogin } from './useGoogleLogin';

export default function SignInScreen({
    route,
}: RootTabScreenProps<'SignIn'> | RootStackScreenProps<'SignInStack'>) {
    const auth = useAuth();
    const [authState, setAuthState] = useAtom(AuthStateAtom);
    const modal = useModal();
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const [signInValues, setSignInValues] = useState({
        email: authState.userEmail || '',
        password: '',
    });
    const [message, setMessage] = useState('');
    const [alert, setAlert] = useState(false);
    const handleChange = (props: any, value: any) => {
        setSignInValues({ ...signInValues, [props]: value.trim() });

        if (props === 'email') {
            setAuthState({ ...authState, userEmail: value.trim() });
        }
    };

    const googleLogin = useGoogleLogin((data) => {
        if (!data.success) {
            modal.open('fail', 'Sorry, we cannot sign you in with Google. Please try again later.');
            return;
        }
        modal.close();
        try {
            navigation.pop();
        } catch (e) {}
    });

    const appleLogin = useAppleLogin((data) => {
        if (!data.success) {
            modal.open('fail', 'Sorry, we cannot sign you in with Apple. Please try again later.');
            return;
        }
        try {
            navigation.pop();
        } catch (e) {}
    });

    const verifyEmail = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        setMessage('Invalid email');
        setAlert(!emailRegex.test(email));
    };

    const handleSignIn = async () => {
        Keyboard.dismiss();
        modal.open('loader');
        const result = await authService.login(authState.userEmail || '', signInValues.password);

        if (!result.success) {
            console.log(result);
            modal.open('fail', result.errorMessage);
        } else {
            await auth.checkIsLoggedIn();
            modal.close();
            navigation.pop();
        }
    };

    const handleSignUp = () => {
        navigation.navigate('SignUp');
    };
    const handleForgotPassword = () => {
        console.log('click forgot password');
        navigation.navigate('PasswordReset', {});
    };
    const handleFacebook = () => {
        console.log('click facebook');
    };
    const handleGoogle = () => {
        // modal.open('loader');
        googleLogin.promptAsync();
    };
    const handleApple = () => {
        appleLogin.signIn();
    };
    const handleWechat = () => {
        console.log('click wechat');
        modal.open('loader', <Text>Wechat</Text>, () => {
            console.log('Wechat');
        });
    };
    const handleBack = () => {
        // navigation.navigate('Root',{screen:'Home',params:{}});
        navigation.goBack();
    };
    return (
        <ScrollView style={styles.pageWrapper}>
            <View style={styles.screenContainer}>
                <View style={styles.topBarContainer}>
                    <TouchableOpacity onPress={handleBack}>
                        <AntDesign name="arrowleft" size={24} />
                    </TouchableOpacity>
                </View>

                <View>
                    <Text style={styles.title}>GourmetCarte</Text>
                    <Text style={styles.subTitle}>Explore the best dish for you</Text>
                </View>

                <View style={styles.inputContainer}>
                    <Text style={styles.inputTitle}>Email</Text>
                    <View style={styles.inputView}>
                        <CustomInputText
                            isPassword={false}
                            leftIcon={'user'}
                            placeholder={'example@email.com'}
                            initValue={authState.userEmail}
                            onChangeText={(text) => handleChange('email', text)}
                        />
                    </View>
                    <Text style={styles.inputTitle}>Password</Text>
                    <View style={styles.inputView}>
                        <CustomInputText
                            isPassword={true}
                            leftIcon={'lock'}
                            placeholder={'********'}
                            onChangeText={(text) => handleChange('password', text)}
                        />
                    </View>
                    <TouchableOpacity onPress={handleForgotPassword}>
                        <Text
                            style={{
                                textAlign: 'right',
                                width: '95%',
                                color: Colors.primary,
                                fontFamily: 'eina01',
                            }}
                        >
                            Forget Password
                        </Text>
                    </TouchableOpacity>
                </View>

                <TouchableOpacity style={styles.buttonContainer} onPress={handleSignIn}>
                    <Text
                        style={{
                            textAlign: 'center',
                            fontSize: 15,
                            color: Colors.white,
                            fontFamily: 'eina01-semibold',
                        }}
                    >
                        Sign In
                    </Text>
                </TouchableOpacity>

                <View style={[CommonStyles.flexRowCenter, { marginTop: 10, flexWrap: 'wrap' }]}>
                    <Text style={{ fontSize: 12, lineHeight: 18, fontFamily: 'eina01' }}>
                        By processing you also agree to the&nbsp;
                    </Text>
                    <TouchableOpacity onPress={() => navigation.navigate('EULA')}>
                        <Text
                            style={{
                                fontSize: 12,
                                lineHeight: 18,
                                fontFamily: 'eina01',
                                color: Colors.primary,
                            }}
                        >
                            End User License Agreement,&nbsp;
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.navigate('TermsOfService')}>
                        <Text
                            style={{
                                fontSize: 12,
                                lineHeight: 18,
                                fontFamily: 'eina01',
                                color: Colors.primary,
                            }}
                        >
                            Term of Service&nbsp;
                        </Text>
                    </TouchableOpacity>
                    <Text style={{ fontSize: 12, lineHeight: 18, fontFamily: 'eina01' }}>
                        and&nbsp;
                    </Text>
                    <TouchableOpacity onPress={() => navigation.navigate('PrivacyPolicy')}>
                        <Text
                            style={{
                                fontSize: 12,
                                lineHeight: 18,
                                fontFamily: 'eina01',
                                color: Colors.primary,
                            }}
                        >
                            Privacy Policy.
                        </Text>
                    </TouchableOpacity>
                </View>

                <View style={styles.text}>
                    <Text style={{ fontFamily: 'eina01' }}>Don't have an account?&nbsp;</Text>
                    <TouchableOpacity onPress={handleSignUp}>
                        <Text style={{ color: Colors.primary, fontFamily: 'eina01' }}>Sign Up</Text>
                    </TouchableOpacity>
                </View>

                <View style={styles.divider}>
                    <View style={{ flex: 1, height: 0.4, backgroundColor: Colors.gray }} />
                    <Text
                        style={{
                            flex: 0.7,
                            textAlign: 'center',
                            color: Colors.dark_gray,
                            fontFamily: 'eina01',
                        }}
                    >
                        sign in with
                    </Text>
                    <View style={{ flex: 1, height: 0.4, backgroundColor: Colors.gray }} />
                </View>

                <View style={styles.socialButtonContainer}>
                    <TouchableOpacity onPress={handleGoogle}>
                        <FontAwesome name="google" size={42} color="black" />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={handleApple}>
                        <FontAwesome name="apple" size={42} />
                    </TouchableOpacity>
                    {/*<TouchableOpacity onPress={handleWechat}>*/}
                    {/*    <FontAwesome name="wechat" size={42} color="black" />*/}
                    {/*</TouchableOpacity>*/}
                </View>

                <PolicyWidget />
            </View>
        </ScrollView>
    );
}
