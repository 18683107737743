import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as ImagePicker from 'expo-image-picker';
import { Restaurant, RestaurantService } from 'gc-common';
import React from 'react';
import { ScrollView, Text, TouchableOpacity } from 'react-native';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { ImageService } from '../../services/image/imageService';
import { RootStackParamList } from '../../types';
import { styles } from './styles';

export const useAdminTools = (restaurant?: Restaurant) => {
    const auth = useAuth();
    const modal = useModal();
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const uploadMenu = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
        });

        if (!result.canceled) {
            modal.open('loader');
            try {
                const uploadedImage = await ImageService.uploadMenu(result.assets[0].uri);
                // console.log('Image uploaded successfully:', uploadedImage);
                modal.close();
                // navigation.push('MenuCreation',{restaurantId: restaurant!.restaurant_id, menu:[]});
                navigation.push('MenuCreation', {
                    restaurantId: restaurant!.restaurant_id,
                    menu: uploadedImage,
                });
            } catch (e) {
                modal.open('fail', 'Image upload failed');
            }
        } else {
            console.log('Image picker canceled');
        }
    };

    const uploadImages = async () => {
        if (!restaurant) {
            return;
        }
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: true,
            selectionLimit: 9,
            quality: 1,
        });

        if (!result.canceled) {
            modal.open('loader');
            if (result.assets.length > 9) {
                modal.open('warning', 'Sorry, we only support 9 photos at maximum.');
            }
            const images = result.assets.map((i) => i.uri);

            try {
                const uploadedImages = await ImageService.uploadImages(images);
                console.log('Images uploaded successfully:', uploadedImages);
                const newImages = uploadedImages.map((i) => i.image_id);
                if (uploadedImages)
                    restaurant['image_ids'] = [...newImages, ...restaurant['image_ids']];
                restaurant.avatar_image_id = newImages[0];
                const rsp = await RestaurantService.update(restaurant.restaurant_id, restaurant);
                // console.log('Restaurant updated:', rsp.data);
                modal.close();
                navigation.replace('RestaurantDetail', { id: restaurant.restaurant_id });
            } catch (e) {
                modal.open('fail', 'Image upload failed');
            }
        }
    };

    const render = () => {
        if (auth?.user?.admin) {
            return (
                <ScrollView
                    horizontal
                    style={styles.extraActionContainer}
                    tw={'bg-white px-4 pt-2'}
                >
                    <TouchableOpacity style={styles.extraButtonWrapper} onPress={uploadMenu}>
                        <Text style={styles.extraButton}>
                            <Ionicons name={'menu'} /> Upload Menu
                        </Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.extraButtonWrapper} onPress={uploadImages}>
                        <Text style={styles.extraButton}>
                            <FontAwesome name={'share-alt'} /> Upload Store Image
                        </Text>
                    </TouchableOpacity>
                </ScrollView>
            );
        } else {
            <></>;
        }
    };
    return {
        render,
    };
};
