import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    // Edit Input Section
    inputGroup: {
        marginVertical: 10,
    },
    inputTitle: {
        marginBottom: 5,
    },
    helperText: {
        color: Colors.dark_gray,
    },

    imageRow: {
        alignItems: 'center',
    },
    imageTitle: {
        marginTop: 5,
        textAlign: 'center',
    },
    imageWrapper: {
        height: 100,
        width: 100,
        borderRadius: 100,
    },
});
