import { Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import React, { ReactNode } from 'react';
import {
    KeyboardTypeOptions,
    Platform,
    Text,
    TextInput,
    TextInputIOSProps,
    TextInputProps,
    TouchableOpacity,
    View,
} from 'react-native';
import { Colors } from '../../constants/Colors';
import { styles } from './CustomInputTextStyles';

export interface InputTextProps<S = any> extends TextInputProps {
    leftIcon?: string;
    isPassword?: boolean;
    placeholder?: string;
    initValue?: any;
    onChangeText?: (props: any) => void;
    multiline?: boolean;
    height?: number;
    borderColor?: string;
    backgroundColor?: string;
    textContentType?: TextInputIOSProps['textContentType'];
    keyboardType?: KeyboardTypeOptions;

    suggestions?: S[];
    renderSuggestion?: (item: S) => ReactNode;
    onSuggestionSelected?: (item: S) => void;
}

const CustomInputText = React.forwardRef<any, InputTextProps>(
    ({ leftIcon, isPassword, placeholder, onChangeText, initValue, ...props }, ref) => {
        const [showPassword, setShowPassword] = React.useState(false);
        const { suggestions, onSuggestionSelected, renderSuggestion } = props;
        let showPasswordIcon = showPassword ? 'eye-off' : 'eye';

        const overrideStyle = {
            height: props.height,
            borderColor: props.borderColor,
            backgroundColor: props.backgroundColor,
        };

        const handleEye = () => {
            if (showPasswordIcon === 'eye') {
                setShowPassword(true);
                showPasswordIcon = 'eye-off';
            } else {
                showPasswordIcon = 'eye-off';
                setShowPassword(false);
            }
        };
        if (isPassword) {
            return (
                <View style={[styles.passwordInputContainer, overrideStyle]}>
                    <Feather
                        style={styles.leftIcon}
                        name={leftIcon as any}
                        size={20}
                        color={Colors.dark_gray}
                    />
                    <TextInput
                        {...props}
                        style={styles.textInput}
                        textContentType={'password'}
                        secureTextEntry={!showPassword}
                        placeholder={placeholder}
                        placeholderTextColor={Colors.dark_gray}
                        onChangeText={onChangeText}
                        ref={ref}
                    />
                    <MaterialCommunityIcons
                        name={showPasswordIcon as any}
                        style={styles.rightIcon}
                        size={20}
                        onPress={handleEye}
                        color={Colors.dark_gray}
                    />
                </View>
            );
        }

        return (
            <View
                style={[
                    styles.passwordInputContainer,
                    overrideStyle,
                    props.multiline && styles.multiline,
                ]}
            >
                <Feather
                    style={styles.leftIcon}
                    name={leftIcon as any}
                    size={20}
                    color={Colors.dark_gray}
                />
                <TextInput
                    {...props}
                    style={[
                        styles.textInput,
                        props.multiline && Platform.OS === 'ios' && { paddingTop: 15 },
                    ]}
                    multiline={props.multiline}
                    value={initValue}
                    placeholder={placeholder}
                    placeholderTextColor={Colors.dark_gray}
                    onChangeText={onChangeText}
                    textContentType={props.textContentType}
                    keyboardType={props.keyboardType}
                    ref={ref}
                />
                {suggestions && (
                    <View style={styles.suggestionWrapper}>
                        {suggestions.map((item, i) => {
                            if (renderSuggestion) return renderSuggestion(item);
                            else
                                return (
                                    <TouchableOpacity
                                        key={i}
                                        style={styles.suggestionItem}
                                        onPress={() => onSuggestionSelected?.(item)}
                                    >
                                        <Text>{item}</Text>
                                    </TouchableOpacity>
                                );
                        })}
                    </View>
                )}
            </View>
        );
    }
);
CustomInputText.defaultProps = {
    height: 45,
    backgroundColor: Colors.light_gray_2,
};

export default CustomInputText;
